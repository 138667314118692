import React, { useCallback } from 'react';
import { Outlet, useNavigate, useOutletContext } from 'react-router-dom';
import Route from 'Sp/Route';
import { ClientBookingData, useClientBookingData } from 'client_react/booking/App';
import { QUERY } from 'client_react/booking/common';

type ClientBookingExperienceData = {
    navigateWithSession: (to: string, newBookingSessionPublicId?: string) => void;
} & ClientBookingData;

const ClientBookingExperience = () => {
    const navigate = useNavigate();
    const clientBookingData = useClientBookingData();

    const navigateWithSession = useCallback(
        (to: string, newBookingSessionPublicId?: string) => {
            const route = Route.get(to, {
                [QUERY.BOOKING_SESSION_ID]:
                    newBookingSessionPublicId ?? clientBookingData.bookingSession?.publicId
            });

            navigate(route);
        },
        [clientBookingData.bookingSession?.publicId, navigate]
    );

    return <Outlet context={{ ...clientBookingData, navigateWithSession } satisfies ClientBookingExperienceData} />;
};

export function useClientBookingExperience() {
    return useOutletContext<ClientBookingExperienceData>();
}

export default ClientBookingExperience;
