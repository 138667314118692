import React from 'react';
import { Box } from '@chakra-ui/layout';

const PageContainer = ({ children }: { children: React.ReactNode }) => {
    return (
        <Box
            bg={{ base: 'paper', lg: 'paperBackground' }}
            color="var(--chakra-colors-text)"
            height="100%"
            width="100%"
        >
            {children}
        </Box>
    );
};

export default PageContainer;
