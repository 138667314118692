import React from 'react';
import { Flex, Heading } from '@chakra-ui/react';

type Props = {
    brandName: string;
};

const Header = ({ brandName }: Props) => {
    return (
        <Flex
            width="full"
            height={{ base: '50px', lg: '80px' }}
            backgroundColor="paper"
            alignItems="center"
        >
            <Heading as="h2" size="sm" color="brand.primary" margin={0} marginLeft="16px">
                {brandName}
            </Heading>
        </Flex>
    );
};

export default Header;
