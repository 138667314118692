import type { Theme } from '@emotion/react';
import type { Appearance, StripeElementsOptions } from '@stripe/stripe-js';
import moment from 'moment';

export const getStripeAppearance = (theme: Theme): Appearance => {
    return {
        rules: {
            '.Checkbox': {
                color: theme.isLightColorScheme ? theme.colors.gray[500] : theme.colors.white,
                fontSize: '14px'
            },
            '.Input:focus': {
                borderColor: theme.colors.brand.primary,
                borderWidth: '2px',
                boxShadow: 'none'
            },
            '.Label': {
                color: theme.isLightColorScheme ? theme.colors.gray[500] : theme.colors.white,
                fontSize: '14px'
            }
        },
        theme: theme.isLightColorScheme ? 'stripe' : 'night',
        variables: {
            colorPrimary: theme.colors.brand.primary,
            fontFamily: 'open-sans, sans-serif'
        }
    };
};

export const getStripeFonts = (staticUrl: string): StripeElementsOptions['fonts'] => [
    {
        family: 'open-sans',
        src: `url(${staticUrl}/common/font/OpenSans-Regular.woff)`,
        weight: '400'
    },
    {
        family: 'open-sans',
        src: `url(${staticUrl}/common/font/OpenSans-SemiBold.woff)`,
        weight: '600'
    },
    {
        family: 'open-sans',
        src: `url(${staticUrl}/common/font/OpenSans-Bold.woff)`,
        weight: '700'
    }
];

// Our translation function replaces special characters (like an apostrophe) with HTML character references,
// so we need to replace them again with the original special characters, to display the correct text in React.
export const replaceHtmlEscapeCharacters = (text: string): string => {
    return new DOMParser().parseFromString(text, 'text/html').documentElement.textContent ?? '';
};

export const getTimeZoneAbbreviation = () => {
    const timeZoneFormatter = new Intl.DateTimeFormat([], {
        timeZoneName: 'short'
    });
    const parts = timeZoneFormatter.formatToParts(new Date());
    return parts.find((x) => x.type === 'timeZoneName')?.value;
};

export const getTimeZoneName = () => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone.replace('_', ' ').replace('/', ', ');
};

export const getBrowserTimeZone = () => {
    const gmtOffset = moment().format('Z');
    return `(GMT${gmtOffset}) ${getTimeZoneName()}`;
};

export const convertTimeToMinutes = (time: Date): number => {
    return time.getHours() * 60 + time.getMinutes();
};
