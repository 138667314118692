import React, { PropsWithChildren } from 'react';
import { Box, useTheme } from '@chakra-ui/react';
import { useBreakpoint } from 'ts/common/hooks';

const Layout = ({ children }: PropsWithChildren) => {
    const isLargeScreen = useBreakpoint();
    const theme = useTheme();

    if (isLargeScreen) {
        return (
            <>
                <Box
                    position="absolute"
                    width="100%"
                    height="300px"
                    backgroundColor="brand.primary"
                />
                <Box
                    position="relative"
                    background="paper"
                    borderRadius="20px"
                    boxShadow="0px 4px 40px 0px rgba(0, 0, 0, 0.08)"
                    fontFamily={theme.fonts}
                    marginX="auto"
                    boxSizing="border-box"
                    maxWidth="960px"
                    padding="40px 34px"
                    marginTop="200px"
                >
                    {children}
                </Box>
            </>
        );
    }

    return (
        <>
            <Box width="100%" backgroundColor="brand.primary" height="200px" />
            <Box background="paper" fontFamily={theme.fonts} padding="16px 16px 96px 16px">
                {children}
            </Box>
        </>
    );
};

export default Layout;
