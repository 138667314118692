import React from 'react';
import { Flex, Heading } from '@chakra-ui/react';
import { useBreakpoint } from 'ts/common/hooks';
import BookingSessionSummary from 'client_react/booking/components/BookingSessionSummary';
import type { IBookingSession, IBookingSessionType } from 'client_react/booking/types';

const BookingSessionHeading = ({
    bookingSession,
    bookingSessionType
}: {
    bookingSession: Nullable<IBookingSession>;
    bookingSessionType: IBookingSessionType;
}) => {
    const isLargeScreen = useBreakpoint();

    return (
        <Flex direction="column" gap="16px" marginBottom="24px">
            <Heading as="h1" margin={0} size={isLargeScreen ? '3xl' : '2xl'} color="brand.primary">
                {bookingSessionType.name}
            </Heading>
            <BookingSessionSummary
                bookingSessionType={bookingSessionType}
                bookingSession={bookingSession}
            />
        </Flex>
    );
};

export default BookingSessionHeading;
