import React from 'react';
import { Flex, Heading, Text } from '@chakra-ui/react';
import { useTranslate } from 'ts/common/hooks';
import BookingSessionSummary from 'client_react/booking/components/BookingSessionSummary';
import { replaceHtmlEscapeCharacters } from 'client_react/booking/helpers';
import { IBookingSession, IBookingSessionType } from 'client_react/booking/types';

type Props = {
    bookingSession: IBookingSession | null;
    bookingSessionType: IBookingSessionType | null;
    showSubtitle: boolean;
};

const ConfirmationContent = ({ bookingSession, bookingSessionType, showSubtitle }: Props) => {
    const t = useTranslate('booking.confirmationPage');

    const requiresApproval =
        bookingSession?.requiresApproval || bookingSessionType?.requiresApproval;

    return (
        <Flex direction="column" gap="16px" marginBottom="24px">
            <Heading as="h1" margin={0} size={{ base: '2xl', lg: '3xl' }} color="brand.primary">
                {t('title')}
            </Heading>
            {showSubtitle && (
                <Text fontSize={{ base: 'md', lg: 'lg' }}>
                    {replaceHtmlEscapeCharacters(
                        t(requiresApproval ? 'needsConfirmationSubtitle' : 'confirmedSubtitle', {
                            brandName: bookingSession?.brandName
                        })
                    )}
                </Text>
            )}
            <BookingSessionSummary
                bookingSessionType={bookingSessionType}
                bookingSession={bookingSession}
                isConfirmation
            />
            {bookingSessionType?.description && (
                <Text marginTop="8px">{bookingSessionType.description}</Text>
            )}
        </Flex>
    );
};

export default ConfirmationContent;
