import React, { FC } from 'react';
import { Global } from 'sp-ui';
import { useBootstrapData, withBootstrapData } from './bootstrap';

export const globalStyles = (staticUrl = '') => `
    @font-face {
        font-family: 'Brandon';
        src: url('${staticUrl}/common/font/BrandonGrotesque-Bold.eot');
        src: url('${staticUrl}/common/font/BrandonGrotesque-Bold.eot?#iefix') format('embedded-opentype'),
            url('${staticUrl}/common/font/BrandonGrotesque-Bold.woff2') format('woff2'),
            url('${staticUrl}/common/font/BrandonGrotesque-Bold.woff') format('woff'),
            url('${staticUrl}/common/font/BrandonGrotesque-Bold.ttf') format('truetype');
        font-weight: bold;
    }

    @font-face {
        font-family: 'Brandon';
        src: url('${staticUrl}/common/font/BrandonText-Regular.eot');
        src: url('${staticUrl}/common/font/BrandonText-Regular.eot?#iefix') format('embedded-opentype'),
            url('${staticUrl}/common/font/BrandonText-Regular.woff2') format('woff2'),
            url('${staticUrl}/common/font/BrandonText-Regular.woff') format('woff'),
            url('${staticUrl}/common/font/BrandonText-Regular.ttf') format('truetype');
        font-weight: normal;
    }

    @font-face {
        font-family: 'Freeland';
        src: url('${staticUrl}/common/font/Freeland.eot');
        src: url('${staticUrl}/common/font/Freeland.eot?#iefix') format('embedded-opentype'),
            url('${staticUrl}/common/font/Freeland.woff2') format('woff2'),
            url('${staticUrl}/common/font/Freeland.woff') format('woff'),
            url('${staticUrl}/common/font/Freeland.ttf') format('truetype');
    }

    @font-face {
        font-family: open-sans;
        src: url('${staticUrl}/common/font/OpenSans-Regular.eot');
        src: url('${staticUrl}/common/font/OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
            url('${staticUrl}/common/font/OpenSans-Regular.woff2') format('woff2'),
            url('${staticUrl}/common/font/OpenSans-Regular.woff') format('woff'),
            url('${staticUrl}/common/font/OpenSans-Regular.ttf') format('truetype');
    }

    @font-face {
        font-family: open-sans;
        font-weight: 600;
        src: url('${staticUrl}/common/font/OpenSans-SemiBold.eot');
        src: url('${staticUrl}/common/font/OpenSans-SemiBold.eot?#iefix') format('embedded-opentype'),
            url('${staticUrl}/common/font/OpenSans-SemiBold.woff2') format('woff2'),
            url('${staticUrl}/common/font/OpenSans-SemiBold.woff') format('woff'),
            url('${staticUrl}/common/font/OpenSans-SemiBold.ttf') format('truetype');
    }

    @font-face {
        font-family: open-sans;
        font-weight: 700;
        src: url('${staticUrl}/common/font/OpenSans-Bold.eot');
        src: url('${staticUrl}/common/font/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
            url('${staticUrl}/common/font/OpenSans-Bold.woff2') format('woff2'),
            url('${staticUrl}/common/font/OpenSans-Bold.woff') format('woff'),
            url('${staticUrl}/common/font/OpenSans-Bold.ttf') format('truetype');
    }

    @font-face {
        font-family: 'ProximaNova';
        src: url('${staticUrl}/common/font/ProximaNova-Regular.eot');
        src: url('${staticUrl}/common/font/ProximaNova-Regular.eot?#iefix') format('embedded-opentype'),
            url('${staticUrl}/common/font/ProximaNova-Regular.woff2') format('woff2'),
            url('${staticUrl}/common/font/ProximaNova-Regular.woff') format('woff'),
            url('${staticUrl}/common/font/ProximaNova-Regular.ttf') format('truetype');
        font-weight: normal;
    }

    @font-face {
        font-family: 'ProximaNova';
        src: url('${staticUrl}/common/font/ProximaNova-Bold.eot');
        src: url('${staticUrl}/common/font/ProximaNova-Bold.eot?#iefix') format('embedded-opentype'),
            url('${staticUrl}/common/font/ProximaNova-Bold.woff2') format('woff2'),
            url('${staticUrl}/common/font/ProximaNova-Bold.woff') format('woff'),
            url('${staticUrl}/common/font/ProximaNova-Bold.ttf') format('truetype');
        font-weight: bold;
    }

    @font-face {
        font-family: 'bambusa-pro-regular';
        src: url('${staticUrl}/common/font/BambusaPro_Regular.eot');
        src: url('${staticUrl}/common/font/BambusaPro_Regular.eot#iefix') format('embedded-opentype'),
            url('${staticUrl}/common/font/BambusaPro_Regular.woff') format('woff'),
            url('${staticUrl}/common/font/BambusaPro_Regular.woff2') format('woff2'),
            url('${staticUrl}/common/font/BambusaPro_Regular.ttf') format('truetype');
        font-weight: normal;
    }

    @font-face {
        font-family: 'scandiebox-one';
        src: url('${staticUrl}/common/font/SBOne-Regular.eot');
        src: url('${staticUrl}/common/font/SBOne-Regular.eot#iefix') format('embedded-opentype'),
            url('${staticUrl}/common/font/SBOne-Regular.woff') format('woff'),
            url('${staticUrl}/common/font/SBOne-Regular.woff2') format('woff2'),
            url('${staticUrl}/common/font/SBOne-Regular.ttf') format('truetype');
    }

    @font-face {
        font-family: 'blooming-elegant';
        src: url('${staticUrl}/common/font/BloomingElegant-Regular.eot');
        src: url('${staticUrl}/common/font/BloomingElegant-Regular.eot#iefix') format('embedded-opentype'),
            url('${staticUrl}/common/font/BloomingElegant-Regular.woff') format('woff'),
            url('${staticUrl}/common/font/BloomingElegant-Regular.woff2') format('woff2'),
            url('${staticUrl}/common/font/BloomingElegant-Regular.ttf') format('truetype');
    }
`;

export const GlobalStyles: FC = withBootstrapData(() => {
    const { staticUrl } = useBootstrapData();

    return <Global styles={globalStyles(staticUrl)} />;
});
